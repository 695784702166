import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Welcome from './Welcome';
import { useHistory, useRouteMatch, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Terms from './Terms';
import Privacy from './Privacy';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  }
}));
function Index(props) {
  const history = useHistory();
  const classes = useStyles();
  const match = useRouteMatch();

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <AppBar position='static'>
            <Toolbar>
              <Typography
                variant='h6'
                style={{ cursor: 'pointer' }}
                onClick={() => history.push('/welcome')}
                className={classes.title}>
                Care4Myeloma
              </Typography>
            </Toolbar>
          </AppBar>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Switch>
            <Route path={`${match.url}/terms`}>
              <Terms />
            </Route>
            <Route path={`${match.url}/privacy`}>
              <Privacy />
            </Route>
            <Route path={`${match.url}`}>
              <Welcome />
            </Route>
          </Switch>
        </Grid>
      </Grid>
    </>
  );
}

export default withRouter(Index);
