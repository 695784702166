import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import api from 'services/api';
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import LinearProgress from '@material-ui/core/LinearProgress';
import html2canvas from 'html2canvas';
import { Button } from '@material-ui/core';
import CustomTimeline from 'components/Timeline';
import useAsyncDataFrom from 'hooks/useAsyncDataFrom';
import waterMark from 'images/myeloma-logo.png';

function UserTimeLine(props) {
  const match = useRouteMatch('/patient-timeline/:id');
  const regimen = useAsyncDataFrom(() => {
    return api.getPatientRegimens(match.params.id);
  })

  let canvasContainer = React.createRef();
  let userRef = React.createRef();

  function printUserTimline() {
    html2canvas(userRef.current).then(canvas => {
      console.log(canvas);
      let anchor = document.createElement('a');
      anchor.download = 'screenshot';
      let img = document.createElement('img');
      img.src = canvas.toDataURL();
      anchor.href = img.src;
      img.setAttribute('width', '100%');
      img.setAttribute('height', 'auto');
      anchor.appendChild(img);
      canvasContainer.current.appendChild(canvas);
      // anchor.click();
    });
  }

  if (regimen.isLoading) {
    return <LinearProgress />;
  }

  return (
    <div>
      <div style={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute'
      }}>
        <div>
          <img src={waterMark} />
        </div>
      </div>
      <Box p={3} style={{ position: 'relative' }}>
        <Grid container justify="center">
          <Grid item xs={12} sm={12} md={7}>
            {regimen.data.map((item, index) => (
              <Box key={index} pb={2}>
                <Box>
                  <Typography variant="subtitle2">{item.reason}</Typography>
                </Box>
                <Box pl={4}>
                  <Grid container justify="space-around" spacing={2}>
                    <Grid item>
                      <Typography variant="caption" color="textSecondary">
                        Type
                </Typography>
                      <Typography variant="body1">
                        {item.regimen_type || 'NA'}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant="caption" color="textSecondary">
                        Regimen
                </Typography>
                      <Typography variant="body1">
                        {item.regimen || 'NA'}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant="caption" color="textSecondary">
                        Cycle
                </Typography>
                      <Typography variant="body1">
                        {item.cycle || 'NA'}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant="caption" color="textSecondary">
                        Duration
                </Typography>
                      <Typography variant="body1">
                        {item.from_date} - {item.to_date}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default UserTimeLine;

// export default function() {
//   return <h1>Hello</h1>
// }