import React from 'react'
import Typography from '@material-ui/core/Typography';
import './Timeline.scss';

function Timeline(props) {
  return (
    <div class="custom-timeline">
      <div class="timeline">
        {props.children}
      </div>
    </div>
  )
}

export default Timeline;
