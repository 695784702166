import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography, Box } from '@material-ui/core';
import Footer from './Footer';

function Privacy() {
  return (
    <>
      <Grid container justify='center'>
        <Grid item xs={8}>
          <Box textAlign='center' mt={4}>
            <Typography variant='h4'>Privacy Policy</Typography>
          </Box>
          <Typography>
            <p>
              As Indian Myeloma Academic Groupe (hereinafter called as IMAGe),
              we are committed to respecting your privacy. IMAGe knows you care
              about how personal information is used and shared, and we take
              privacy seriously. This privacy policy covers how IMAGe ("we" or
              "us") treats personal information that is collected and received.
              Personal information is information about you that is personally
              identifiable like your name, address, email address, health
              community, treatment schedule, or phone number, and that is not
              otherwise publicly available. This policy does not apply to the
              practices of companies that we do not own or control, or to people
              that we do not employ or manage.
            </p>
            <p>
              Please read the following to learn more about our Privacy Policy.
              By using or accessing (care4myeloma in), our mobile application
              (Care4myeloma), and interacting with our Professionals
              (collectively, the “Services”) in any manner, you acknowledge that
              you accept the practices and policies outlined in this Privacy
              Policy, and you hereby consent that we will collect, use, and
              share personal information in the following ways.
            </p>
            <p>
              Remember that your use of services is at all times subject to the
              Terms of Use if accessing our website or mobile application, each
              of which incorporates this Privacy Policy.
            </p>
            <p>
              <b>What does this Privacy Policy cover?</b>
            </p>
            <p>
              This Privacy Policy covers our treatment of personally
              identifiable information (“Personal Information”) that we gather
              when you are accessing or using our Services, but not to the
              practices of companies we don’t own or control, or people that we
              don’t manage. We gather various types of Personal Information from
              our users, as explained in more detail below, and we use this
              Personal Information internally in connection with our Services,
              including to personalize, provide, and improve our services, to
              allow you to set up a user account and profile, to contact you and
              allow other users to contact you, to fulfill your requests for
              certain products and services, and to analyze how you use the
              Services.
            </p>
            <p>
              <b>What information does (Care4myeloma App) collect?</b>
            </p>
            <p>Information you provide us.</p>
            <p>
              When you register for our services, we ask for information
              including your name, email address, health community, treatment
              schedule, mobile number, postal code, and profile picture.
            </p>

            <p>When you log treatment events we store your health progress.</p>
            <p>
              From time to time, we may ask you for other types of information
              like demographics, for example, when you participate in a survey
              or product test or when you enter a contest or other event.
            </p>
            <p>
              <b>Information we may automatically collect.</b>
            </p>
            <p>
              When you visit our website or application, some information is
              automatically collected. For example, when you visit our
              application your mobile operating system, Internet Protocol (IP)
              address, access times, browser type and language, and the website
              you visited before our site are logged automatically. We also
              collect information about your usage and activity on our website.
            </p>
            <p>
              When you use our mobile application, we may automatically collect
              data about your device such as your IMEI no. and how you use our
              application including pages you view.
            </p>
            <p>
              <b>Information we receive from third parties.</b>
            </p>
            <p>
              We may also collect additional information about you from third
              parties to assist us in providing you with services. For example,
              if you are a physician, we compare the name, medical school and
              graduation information you provide with the database to help
              verify the accuracy of our subscriber database.
            </p>
            <p>
              <b>Will (Care4myeloma App) ever change this Privacy Policy?</b>
            </p>
            <p>
              We’re constantly trying to improve our Services, so we may need to
              change this Privacy Policy from time to time as well. If we make
              any material changes, we will alert you to changes by sending you
              an email, and/or by some other means of notice prior to the change
              becoming effective. Please note that if you’ve opted not to
              receive legal notice emails from us (or you haven’t provided us
              with your email address), those legal notices will still govern
              your use of the Services, and you are still responsible for
              reading and understanding them. If you use the Services after any
              changes to the Privacy Policy have been posted, that means you
              agree to all of the changes.
            </p>
            <p>
              If we make material changes to how we use Personal Information
              collected from children under 13, we will notify parents by email
              in order to obtain verifiable parental consent for the new uses of
              the child’s Personal Information.
            </p>

            <p>
              <b>How does (Care4myeloma App) use the information we collect?</b>
            </p>
            <p>We use of information collected through our Services to:</p>
            <p>
              1. Manage your illness (Plasma cell dyscarsias such as Myeloma,
              Amyloidosis, waldenstorms, etc.
            </p>
            <p>2. To customize content you see.</p>
            <p>3. Operate and improve our Services;</p>
            <p>4. Respond to your comments and questions;</p>
            <p>5. Provide and deliver products and services you request;</p>
            <p>
              6. Send you related information, including confirmations,
              technical notices, updates, security alerts, and support messages;
            </p>
            <p>
              7. Better understand you so that we may tailor messaging and
              services based on your interests, preferences, and needs;
            </p>
            <p>
              8. Better understand how you use the service and thus optimize the
              service based on your interests, preferences and needs.
            </p>
            <p>
              9. Communicate with you via text message, email, mobile alerts and
              other messaging services about commercial,{' '}
              <nobr>non-commercial,</nobr> sponsored and{' '}
              <nobr>non-sponsored</nobr> clinical information, please note that
              you can opt out from any messaging service at any moment;
            </p>
            <p>
              10. Send you information via email about products and services
              offered by IMAGe and our selected partners; by accepting the terms
              of this Policy, you are opting in to receiving these emails from
              IMAGe. If you do not wish to receive these kinds of emails, you
              may choose to opt out by updating your profile.
            </p>
            <p>
              11. Send you invitations, by email or other means, to market
              research survey opportunities through programs; by accepting the
              terms of this Policy, you are opting in to receiving these
              invitations from IMAGe. If you do not wish to receive these
              invitations, you may choose to opt out by updating your profile.
            </p>
            <p>
              12. Link or combine your information with other personal
              information we collect through our Services or get from third
              parties, to help understand your needs and expand our products and
              services.
            </p>
            <p>
              13. We receive and store any information you, your child, or your
              healthcare partners knowingly provide to us. For example, we may
              collect Personal Information such as name, email address, parent’s
              email address, phone number, parent’s phone number, mailing
              address, affiliated organizations, gender, age, weight, height,
              photograph, and health and <nobr>well-being</nobr> related
            </p>
            <p>
              information (including, without limitation,{' '}
              <nobr>health-related</nobr> records, results, data, or information
              you or your healthcare partners provide to us).
            </p>
            <p>
              14. Certain information may be required to register with us or to
              take advantage of some of our features. When you download and use
              our mobile application, we automatically collect data on the type
              of device you use, operating system version, and the device
              identifier (or “UDID”). We may ask you for, access or track
              location based data from your mobile while downloading or using
              our mobile app or services for the purposes of providing our
              services to you.
            </p>
            <p>
              15. You may
              <nobr>opt-out</nobr> of having your location information used by
              turning it off at the device level.
            </p>
            <p>
              16. We may occasionally send you push notifications through our
              mobile applications to send you information about your medication,
              alerts, reminders or other information you setup that may be of
              importance to you. You may at any time <nobr>opt-out</nobr> from
              receiving these types of communications by turning them off at the
              device level through your settings.
            </p>
            <p>
              17. We may communicate with you if you’ve provided us the means to
              do so. For example, if you’ve given us your email address, we may
              send you promotional email offers, or email you about your use of
              the Services. Also, we may receive a confirmation when you open an
              email from us. This confirmation helps us make our communications
              with you more interesting and improve our services. If you do not
              want to receive promotional or newsletter communications from us,
              please indicate your preference by following the unsubscribe link
              in our communications or contacting us here
              indian.myeloma.academic.group@gmail.com.
            </p>
            <p>
              18. We will also send you service related email announcements on
              rare occasions when it is necessary to do so. For instance, if our
              Service is temporarily suspended for maintenance, we might send
              you an email. You do not have an option to opt out of these
              emails, which are not promotional in nature.
            </p>
            <p>
              19. Whenever you interact with our Services, we automatically
              receive and record information on our server logs from your
              browser or device, which may include your internet protocol (IP)
              address, device identification, “cookie” information, the type of
              browser and/or device you’re using to access our Services,
              internet service provider (ISP), referring/exit pages, operating
              system, date/time stamp, clickstream data and the page or feature
              you requested. We may combine this automatically collected log
              information with other information we collect about you. We do
              this to improve services we offer you.
            </p>

            <p>
              <b>How does (Care4myeloma App) protect your information?</b>
            </p>
            <p>
              We are committed to respecting your privacy. We take appropriate
              measures to protect the information that we receive about you from
              unauthorized access, disclosure, alteration, or destruction. No
              method of transmission over the Internet or electronic storage is
              100% secure; as a result, we cannot guarantee absolute security.
            </p>
            <p>
              <b>
                Will (Care4myeloma App) share the information it collects with
                others?
              </b>
            </p>
            <p>
              We do not rent, sell, or share personal information about you with
              other people except to provide services you've requested, when we
              have your permission, or under the following circumstances: we
              respond to subpoenas, court orders, or legal process, or to
              establish / exercise our legal rights or defend against legal
              claims; We believe it is necessary to share information in order
              to investigate, prevent, or take action regarding illegal
              activities; violations of our Terms and Conditions, or as
              otherwise required by law.
            </p>
            <p>
              IMAGe may share aggregated or deidentified information such as
              statistics or health behavior patterns by patient segment. This
              information will not include any information directly identifying
              you.
            </p>
            <p>
              If you post a comment or message in our blog or other public
              forums, it will be shared publicly and display your identity.
            </p>
            <p>We may only share personal information as follows:</p>
            <p>
              1. With your consent, for example, when you agree to our sharing
              your information with other third parties for their own purposes
              subject to their separate privacy policies;
            </p>
            <p>
              2. With third party vendors, consultants and other service
              providers with whom we contract to help us provide our Services.
              For example, we may work with companies to host and maintain our
              data and website properties, analyze our data, or provide
              marketing assistance. All contracted third parties must agree not
              to use your personal information other than to fulfill their
              responsibilities to us. So the level of privacy does not vary;
            </p>
            <p>3. to</p>
            <p>
              (i) Comply with laws or to respond to lawful requests and legal
              process,
            </p>

            <p>
              (ii) To protect the rights and property of our agents, patients,
              and others including to enforce our agreements, policies and terms
              of use or
            </p>
            <p class='p41 ft13'>
              (iii) In an emergency to protect the personal safety of IMAGe, its
              patients, or any person;
            </p>
            <p class='p24 ft12'>
              4. In connection with or during negotiation of any merger,
              financing, acquisition or bankruptcy transaction or proceeding
              involving sale or transfer of all or a portion of our business or
              assets to another company.
            </p>
            <p class='p42 ft0'>
              <b>
                What access does (Care4myeloma website / App) give you to your
                personal information?
              </b>
            </p>
            <p class='p8 ft26'>
              You may amend any inaccuracies or update your profile, including
              your personal information and email preferences, at any time.
            </p>
            <p class='p43 ft12'>
              You have the option of correcting, updating, changing contact,
              clinical and demographic information and other information
              previously provided and stored in our database. If you wish to
              correct, update or change your information, simply update your
              profile.
            </p>
            <p class='p10 ft12'>
              You may opt out of any promotional emails at any time by following
              the unsubscribe instructions in the promotional emails you receive
              from us, by updating your profile as described below or by sending
              an email to indian.myeloma.academic.group@gmail.com
            </p>
            <p class='p12 ft12'>
              You may opt out of any IMAGe market research program at any time
              by following the unsubscribe instructions in the invitation emails
              you receive from us, by updating your profile as described below
              or by sending an email to indian.myeloma.academic.group@gmail.com
            </p>
            <p class='p44 ft0'>
              <b>What Personal Information can I access?</b>
            </p>
            <p class='p45 ft12'>
              Upon request IMAGe will provide you with information about whether
              we hold, or process on behalf of a third party, any of your
              personal information. To request this information please contact
              us at indian.myeloma.academic.group@gmail.com, go to the edit
              profile section of your Account.
            </p>
            <p class='p13 ft33'>
              Through your account settings, you may access, and, in some cases,
              edit or delete the following information you’ve provided to us
              name, email address, phone number, mailing address, affiliated
              organizations, gender, age, weight, height, photograph, and health
              and <nobr>well-being</nobr> related information (including,
              without limitation, <nobr>health-related</nobr> records, results,
              data, or information you or your
            </p>

            <p class='p46 ft10'>
              healthcare partners provide to us) or you may also contact us by
              telephone or postal mail at the contact information listed below.
              We will respond to your request to access within 30 days.
            </p>
            <p class='p47 ft12'>
              The information you can view, update, and delete may change as the
              Services change. If you have any questions about viewing or
              updating information we have on file about you, please contact us
              at indian.myeloma.academic.group@gmail.com
            </p>
            <p class='p12 ft26'>
              If you are a parent or guardian of a user of our Services who is
              under 13, you may contact us at any time to ask that
            </p>
            <p class='p48 ft13'>
              (a) We stop collecting Personal Information from such user, and
            </p>
            <p class='p49 ft10'>
              (b) We delete any Personal Information already collected from such
              user and refuse further collection and use of such information.
            </p>
            <p class='p4 ft0'>
              <b>What choices do I have?</b>
            </p>
            <p class='p50 ft26'>
              You can always opt not to disclose information to us, but keep in
              mind some information may be needed to register with us or to take
              advantage of some of our features.
            </p>
            <p class='p10 ft12'>
              You may be able to add, update, or delete information as explained
              above. When you update information, however, we may maintain a
              copy of the unrevised information in our records. You may request
              deletion of your account by emailing us at
              indian.myeloma.academic.group@gmail.com
            </p>
            <p class='p51 ft33'>
              We will retain yours or your child’s information for as long as an
              account is active or as needed to provide services. We will retain
              and use the information as necessary to comply with our legal
              obligations, resolve disputes, and enforce our agreements. Some
              information may remain in our records after deletion. We may use
              any aggregated data derived from or incorporating Personal
              Information after you update or delete it, but not in a manner
              that would identify someone personally.
            </p>
            <p class='p52 ft26'>
              Information Related to Data Collected through the website or
              application Professional and Services Information Related to Data
              Collected for our Clients:
            </p>
            <p class='p53 ft37'>
              IMAGe collects information under the direction of its Clients, and
              has no direct relationship with the individuals whose personal
              information it processes. If you are a customer of one of our
              Clients and would no longer like to be contacted by one of our
              Clients that use our service, please contact the Client that you
              interact with directly. We may transfer personal information to
              companies that help
            </p>

            <p class='p28 ft10'>
              us provide our service. Transfers to subsequent third parties are
              covered by the service agreements with our Clients.
            </p>
            <p class='p54 ft13'>
              Access and Retention of Data Controlled by our Clients:
            </p>
            <p class='p55 ft23'>
              IMAGe has no direct relationship with the individuals whose
              personal information it processes. An individual who seeks access,
              or who seeks to correct, amend, or delete inaccurate information
              should direct his query to
              indian.myeloma.academic.group@gmail.com. If requested to remove
              information we will respond within 30 days.
            </p>
            <p class='p30 ft9'>
              We will retain personal information we process on behalf of our
              Clients for as long as needed to provide services to our Client.
              IMAGe will retain this personal information as necessary to comply
              with our legal obligations, resolve disputes, and enforce our
              agreements.
            </p>
            <p class='p56 ft0'>
              <b>What if I have questions about this policy?</b>
            </p>
            <p class='p49 ft26'>
              If you have any questions or concerns regarding our privacy
              policies, please send us a detailed message to
              indian.myeloma.academic.group@gmail.com
            </p>
            <p class='p57 ft26'>
              (or) via postal mail at Army Hospital (Research &amp; Referral)
              Near Dhaula Kuan Metro Station, Delhi Cantt, New Delhi - 110010,
              and we will try to resolve your concerns.
            </p>
          </Typography>
        </Grid>
      </Grid>
      <Footer position='' />
    </>
  );
}

export default Privacy;
