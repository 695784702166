import axios from './axios';

const urls = {
  login: '/user/login',
  adminLogin: '/user/admin_login',
  hospitalLogin: '/hospitals/login',
  technicalProblems: '/technical_problems',
  patients: '/patients',
  patientDiagnosis: '/diagnosis/',
  patientRegimens: '/regimens',
  patientReports: '/patient_reports/',
  // investigations: '/investigations/?attribute=',
  sideEffects: '/side_effects/',
  complaints: '/complaints/',
  hospitalisations: '/hospitalisation/',
  radio: '/radio_therapy/',
  surgery: '/surgery/',
  dashboardNumbers: '/hospitals/6/dashboard',
  statistics: '/patient/statistics?patient=',
  prescriptions: '/medications',
  drugIntake: '/drug_intake',
  patient: '/patient',
  exportbyfilter: '/patient/export',
  followups: '/followup',
  therapies: '/therapies',
  investigations: '/investigations',
  dashboard: '/statistics',
  transplants: '/transplants',
  updatePassword: '/user/change_password',
  hospitals: '/hospitals',
  responseAttained: '/responses_attained',
  exportsSideEffects: '/exports/side_efffects',
  postTransplantInvestigations: '/transplant_investigations',
};

function getTransplantData(query) {
  return axios.get(`/transplant_eav/get_values`, {
    query
  });
}

function login(data) {
  return axios.post(urls.login, data);
}

function adminLogin(data) {
  return axios.post(urls.adminLogin, data);
}
function hospitalLogin(data) {
  return axios.post(urls.hospitalLogin, data);
}

function updatePassword(data) {
  return axios.post(urls.updatePassword, data);
}

function getPatientDiagnosis(id) {
  return axios.get(`${urls.patientDiagnosis}?patient=${id}`);
}

function updatePatientDiagnosis(id, data) {
  return axios.post(`${urls.patientDiagnosis}${id}`, data);
}

function postDiagnosis(data) {
  return axios.post(`${urls.patientDiagnosis}/create`, data);
}

function getPatientRegimens(id) {
  return axios.get(`${urls.patientRegimens}/?patient=${id}`);
}
function addPatientRegimens(data) {
  return axios.post(`${urls.patientRegimens}/`, data);
}

function updatePatientRegimens(data, id) {
  console.log(data);
  return axios.patch(`${urls.patientRegimens}/${id}/`, data);
}
function deletePatientRegimens(id) {
  return axios.delete(`${urls.patientRegimens}/${id}/`);
}

function getPatientsList(link) {
  return axios.get(`${link}`);
}
function getTechnicalProblems() {
  return axios.get(`${urls.technicalProblems}/`);
}

function createTechnical(data) {
  return axios.post(`${urls.technicalProblems}/`, data);
}

function updateTechnicalProblems(id, data) {
  return axios.put(`${urls.technicalProblems}/${id}/`, data);
}

function deleteTechnicalProblems(id) {
  return axios.delete(`${urls.technicalProblems}/${id}/`);
}

function getPatients(id) {
  return axios.get(`${urls.patients}/?hospital=${id}`);
}

function getPatient(id) {
  return axios.get(`${urls.patient}/${id}`);
}

function suspendPatient(id) {
  return axios.delete(`${urls.patient}/${id}/suspend_patient`);
}
function createPatient(data) {
  return axios.post(`/patient/create`, data);
}

function getPatientsInformation(id) {
  return axios.get(`${urls.patient}/${id}`);
}

function updatePatientInfo(id, data) {
  return axios.patch(`${urls.patient}/${id}/update`, data);
}

function getRegimens(id) {
  return axios.get(`${urls.regimens}/?patient=${id}/`);
}

function getPatientsForMaps(id) {
  return axios.get(`${urls.patient}/get_patient_location?hospital=${id}`);
}
function getInvestigations(attribute, id) {
  return axios.get(
    `${urls.investigations}/?attribute=${attribute}&patient=${id}`
  );
}

function filterPatients(
  id,
  fileNumber,
  phone,
  ustate,
  selectedDate,
  gender,
  died,
  mobile
) {
  return axios.get(
    `${urls.patients}/?hospital=${id}&file_no=${fileNumber}&phone=${phone}&state=${ustate}&last_followup_date=${selectedDate}&gender=${gender}&is_died=${died}`
  );
}

function filterPatient(fileNumber) {
  return axios.get(`${urls.patients}/?file_no=${fileNumber}`);
}

function getSideEffects(id) {
  return axios.get(`${urls.sideEffects}?patient=${id}`);
}

function updatePatientSideEffects(data, id) {
  return axios.put(`${urls.sideEffects}${id}/`, data);
}

function deleteSideEffects(id) {
  return axios.delete(`${urls.sideEffects}${id}/`);
}

function createPatientSideEffects(data) {
  return axios.post(`${urls.sideEffects}`, data);
}

function getPatientComplaints(id) {
  return axios.get(`${urls.complaints}?patient=${id}`);
}

function createComplaint(data) {
  return axios.post(`${urls.complaints}`, data);
}
function updateComplaints(data, id) {
  return axios.patch(`${urls.complaints}${id}/`, data);
}
function deleteComplaints(id) {
  return axios.delete(`${urls.complaints}${id}/`);
}

function getPatientHospitalisations(id) {
  return axios.get(`${urls.hospitalisations}?patient=${id}`);
}

function createHospitalisation(data) {
  return axios.post(`${urls.hospitalisations}`, data);
}

function updateHospitalisation(data, id) {
  return axios.patch(`${urls.hospitalisations}${id}/`, data);
}

function deleteHospitalisation(id) {
  return axios.delete(`${urls.hospitalisations}${id}/`);
}

function getRadioTherapy(id) {
  return axios.get(`${urls.radio}?patient=${id}`);
}
function postRadioTherapy(data) {
  return axios.post(`${urls.radio}`, data);
}

function updateRadioTherapy(data, id) {
  return axios.patch(`${urls.radio}${id}/`, data);
}

function deleteRadioTherapy(id) {
  return axios.delete(`${urls.radio}${id}/`);
}
//Surgery
function getSurgery(id) {
  return axios.get(`${urls.surgery}?patient=${id}`);
}
function postSurgery(data) {
  return axios.post(`${urls.surgery}`, data);
}

function updateSurgery(data, id) {
  return axios.put(`${urls.surgery}${id}/`, data);
}

function deleteSurgery(id) {
  return axios.delete(`${urls.surgery}${id}/`);
}

function diedPatients(id) {
  return axios.get(`${urls.patients}/?hospital=${id}&is_died=1`);
}
function getDashboardNumbers() {
  return axios.get(`${urls.dashboardNumbers}`);
}
function getLastFollowUpPatientsList(last_follow_up, id) {
  return axios.get(
    `${urls.patients}/?hospital=${id}&last_followup_date=${last_follow_up}`
  );
}

function getStatistics(id) {
  return axios.get(`${urls.statistics}${id}/`);
}

function getPatientPrescriptions(id) {
  return axios.get(`${urls.prescriptions}/?patient=${id}`);
}

function getPatientDrugIntake(id) {
  return axios.get(`${urls.drugIntake}/?patient=${id}`);
}

function exportData(fileNumber, phone, ustate, monthsValue, gender, died) {
  return axios.get(
    `${urls.exportbyfilter}?file_no=${fileNumber}&phone=${phone}&state=${ustate}&last_followup_date=${monthsValue}&gender=${gender}&is_died=${died}`
  );
}

function getFollowups(id) {
  return axios.get(`${urls.followups}/?patient=${id}`);
}

function getFollowup(id) {
  return axios.get(`${urls.followups}/${id}/`);
}

function deleteFollowup(id) {
  return axios.delete(`${urls.followups}/${id}/`);
}

function updateFollowUp(data, id) {
  return axios.patch(`${urls.followups}/${id}/`, data);
}

function createFollowup(data) {
  return axios.post(`${urls.followups}/`, data);
}

function getTherapies(id) {
  return axios.get(`${urls.therapies}/?patient=${id}`);
}

function updateTherapies(data, id) {
  return axios.patch(`${urls.therapies}/${id}/`, data);
}

function addTherapies(data) {
  return axios.post(`${urls.therapies}/`, data);
}
function deleteTherapy(id) {
  return axios.delete(`${urls.therapies}/${id}/`);
}

function getAllInvestigations(id) {
  return axios.get(`${urls.investigations}/list?patient=${id}`);
}

function getInvestigation(id) {
  return axios.get(`${urls.investigations}/${id}`);
}

function deleteInvestigation(id) {
  return axios.delete(`${urls.investigations}/delete/${id}`);
}

function createInvestigation(id, data) {
  return axios.post(`${urls.investigations}/create/${id}`, data);
}
function UpdateInvestigationDate(data) {
  return axios.patch(`${urls.investigations}/edit/${data.id}`, data);
}

function updateInvestigation(data, id) {
  return axios.post(`${urls.investigations}/update/${id}`, data);
}

function createMember(id, data) {
  return axios.post(`/hospitals/${id}/members/create`, data);
}

function getMemers(id) {
  return axios.get(`/hospitals/${id}/members`);
}

function getMember(hospitalId, memberId) {
  return axios.get(`/hospitals/${hospitalId}/members/details/${memberId}`);
}

function updateMember(hospitalId, memberId, data) {
  return axios.patch(`/hospitals/${hospitalId}/members/${memberId}`, data);
}

function deleteMember(hospital_id, id) {
  return axios.delete(`/hospitals/${hospital_id}/members/${id}/destroy`);
}

// dashboard apis

function getLastFollowUpPatients(months, id) {
  return axios.get(
    `${urls.dashboard}/lost_to_followup?months=${months}&hospital=${id}`
  );
}

function getNoOfTransplants(months, id) {
  return axios.get(
    `${urls.dashboard}/no_of_transplants?months=${months}&hospital=${id}`
  );
}

function getTotalPatientsCount(months, id) {
  return axios.get(
    `${urls.dashboard}/no_of_users?role=patient&months=${months}&hospital=${id}`
  );
}

function getMobileUsersCount(months, id) {
  console.log(months);
  return axios.get(
    `${urls.dashboard}/no_of_mobile_users?months=${months}&hospital=${id}`
  );
}

function getUpComingAppointmentsCount(weeks, id) {
  return axios.get(
    `${urls.dashboard}/upcoming_appointments?weeks=${weeks}&hospital=${id}`
  );
}

function getDeadPatientsCount(months, id) {
  return axios.get(
    `${urls.dashboard}/no_of_deaths?months=${months}&hospital=${id}`
  );
}

function gethospitalStaff(months, id) {
  return axios.get(
    `${urls.dashboard}/no_of_staff?months=${months}&hospital=${id}`
  );
}

// Transplants

function getTransplants(id) {
  return axios.get(`${urls.transplants}/?patient=${id}`);
}

function getTransplantEntityTypes(type) {
  return axios.get(`/transplant_eav/get_attributes?entity_type=${type}`);
}

function getTransplantValues(params) {
  return axios.get(`transplant_eav/get_values`, {
    params
  });
}

function getTransplant(id) {
  return axios.get(`${urls.transplants}/${id}/`);
}

function deleteTransplant(id) {
  return axios.delete(`${urls.transplants}/${id}`);
}

function updateTransplant(data, id) {
  return axios.patch(`${urls.transplants}/${id}/`, data);
}

function createTransplantMeta(data) {
  return axios.post('/transplants/', data);
}

function deleteTransplantMeta(id) {
  return axios.delete(`${urls.transplants}/${id}/`);
}

function createTransplant(data, config) {
  return axios.post(`/transplant_eav/save_collection`, data, config);
}

function createTransplantEntity(data) {
  return axios.post(`/transplant_eav/create_entity_record`, data);
}

function deleteTransplantEntity(data) {
  return axios.delete(`/transplant_eav/delete_entity_record`, { data });
}

//atributes
function getAttributes() {
  return axios.get(`${urls.transplants}/attributes`);
}

function getProfilaxis(id, name) {
  return axios.get(`${urls.transplants}/${id}/collection?name=${name}`);
}

function addProfilaxis(data) {
  return axios.post(`${urls.transplants}/save`, data);
}

function getHospitals() {
  return axios.get(`${urls.hospitals}/`);
}

//Response Attained till now
function getResponseAttained(id) {
  return axios.get(`${urls.responseAttained}/?patient=${id}`);
}

function createResponseAttained(data) {
  return axios.post(`${urls.responseAttained}/`, data);
}
function updateResponseAttained(data, id) {
  return axios.patch(`${urls.responseAttained}/${id}/`, data);
}
function deleteResponseAttained(id) {
  return axios.delete(`${urls.responseAttained}/${id}/`);
}

//exports
function exportSideEffects() {
  return axios.get(`${urls.exportsSideEffects}`);
}


function getPostTransplantInvestigations(id) {
  return axios.get(`${urls.postTransplantInvestigations}/?transplant=${id}`);
}
function addPostTransplantInvestigations(data) {
  return axios.post(`${urls.postTransplantInvestigations}/`, data);
}

function updatePostTransplantInvestigations(data, id) {
  console.log(data);
  return axios.patch(`${urls.postTransplantInvestigations}/${id}/`, data);
}
function deletePostTransplantInvestigations(id) {
  return axios.delete(`${urls.postTransplantInvestigations}/${id}/`);
}

function getImaging(id) {
  return axios.get(`${urls.patientReports}?patient=${id}`);
}

function getSideEffectsExport(id) {
  return axios.get(`/export/side_effects?hospital=${id}`);
}

function getHospitalisationsExport(id) {
  return axios.get(`/export/hospitalizations?hospital=${id}`);
}
function getComplaintsExport(id) {
  return axios.get(`/export/complaints?hospital=${id}`);
}

function getExportPatientsInformation(id) {
  return axios.get(`/export/patient_personal_information?hospital=${id}`);
}

function getExportSurgery(id) {
  return axios.get(`/export/surgery?hospital=${id}`);
}
function getExportFollowups(id) {
  return axios.get(`/export/followups?hospital=${id}`);
}
function getDiagnosisExport(id) {
  return axios.get(`/export/diagnosis?hospital=${id}`);
}
function getTransplantExport(id) {
  return axios.get(`/export/transplants?hospital=${id}`);
}
function getInvestigationsExport(id) {
  return axios.get(`/export/investigations?hospital=${id}`);
}


function exportResponseTillAttained(id) {
  return axios.get(`/export/responses_attained?hospital=${id}`);
}
function getTransplantReport(id) {
  return axios.get(`/transplant/export?id=${id}`);
}

function exportRadioTheropy(id) {
  return axios.get(`/export/radio_therapy?hospital=${id}`);
}

function investigationImaging(data) {
  return axios.post(`/patient_reports/`, data);
}

function sendNotifications(data) {
  return axios.post(`/notifications/send`, data);
}

export default {
  login,
  adminLogin,
  hospitalLogin,
  updatePassword,

  getTechnicalProblems,
  createTechnical,
  updateTechnicalProblems,
  deleteTechnicalProblems,
  getPatients,
  getPatient,
  createPatient,
  suspendPatient,
  getPatientsList,
  postDiagnosis,
  getPatientDiagnosis,

  getPatientRegimens,
  updatePatientRegimens,
  deletePatientRegimens,
  addPatientRegimens,
  getPatientsForMaps,

  getPatientsInformation,
  updatePatientInfo,
  getRegimens,

  getInvestigations,
  deleteInvestigation,

  filterPatients,
  filterPatient,

  getSideEffects,
  createPatientSideEffects,
  updatePatientSideEffects,
  deleteSideEffects,

  getPatientComplaints,
  createComplaint,
  updateComplaints,
  deleteComplaints,

  getPatientHospitalisations,
  createHospitalisation,
  updateHospitalisation,
  deleteHospitalisation,

  getRadioTherapy,
  postRadioTherapy,
  updateRadioTherapy,
  deleteRadioTherapy,

  diedPatients,
  getDashboardNumbers,
  getStatistics,

  getSurgery,
  postSurgery,
  updateSurgery,
  deleteSurgery,

  getPatientPrescriptions,
  getPatientDrugIntake,

  updatePatientDiagnosis,
  exportData,

  getFollowups,
  updateFollowUp,
  getFollowup,
  deleteFollowup,
  createFollowup,

  getTherapies,
  updateTherapies,
  addTherapies,
  deleteTherapy,

  getAllInvestigations,
  createInvestigation,
  getInvestigation,
  updateInvestigation,
  UpdateInvestigationDate,

  createMember,
  getMemers,
  getMember,
  updateMember,
  deleteMember,

  getTotalPatientsCount,
  getMobileUsersCount,
  getLastFollowUpPatients,
  getUpComingAppointmentsCount,
  getDeadPatientsCount,
  gethospitalStaff,
  getLastFollowUpPatientsList,

  //transplants
  getTransplants,
  getTransplant,
  deleteTransplant,
  updateTransplant,
  createTransplant,
  getTransplantData,
  getTransplantEntityTypes,
  getTransplantValues,
  createTransplantEntity,
  createTransplantMeta,
  deleteTransplantMeta,
  deleteTransplantEntity,

  //attributes
  getAttributes,
  getProfilaxis,
  addProfilaxis,
  getHospitals,

  //response attained
  getResponseAttained,
  createResponseAttained,
  updateResponseAttained,
  deleteResponseAttained,

  getNoOfTransplants,
  exportSideEffects,

  getPostTransplantInvestigations,
  addPostTransplantInvestigations,
  updatePostTransplantInvestigations,
  deletePostTransplantInvestigations,

  getImaging,
  getSideEffectsExport,
  getHospitalisationsExport,
  getComplaintsExport,
  exportResponseTillAttained,
  exportRadioTheropy,
  investigationImaging,
  getExportPatientsInformation,
  getExportSurgery,
  getExportFollowups,
  sendNotifications,
  getDiagnosisExport,
  getTransplantExport,
  getInvestigationsExport,
  getTransplantReport
};
