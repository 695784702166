import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import Footer from './Footer';
function Terms() {
  return (
    <>
    <Box>
      <Grid container justify='center'>
        <Grid item xs={8}>
          <Box textAlign='center' mt={4}>
            <Typography variant='h4'>Terms and Conditions</Typography>
          </Box>
          <Typography>
            <p>
              Indian Myeloma Academic Groupe (“IMAGe”) is the author and
              publisher of the internet resource care4myeloma.in and the mobile
              application ‘care4myeloma’ (together, “Website”). IMAGe owns and
              operates the services provided through the Website.
            </p>
          </Typography>
          <Typography>
            <h3>1.NATURE AND APPLICABILITY OF TERMS</h3>
            <p>
              Please carefully go through these terms and conditions (“Terms”)
              and the privacy policy available at
              https://care4myeloma.in/privacy (“Privacy Policy”) before you
              decide to access the mobile application or avail the services made
              available on the application by IMAGe. These Terms and the Privacy
              Policy together constitute a legal agreement (“Agreement”) between
              you and IMAGe in connection with your use of the application and
              Services (as defined below).
            </p>
            <p>The Agreement applies to you whether you are -</p>
            <p>
              i. A medical practitioner or health care provider (whether an
              individual professional or an organization) or similar institution
              wishing to be listed, or already listed, on the Mobile
              application, including designated, authorized associates of such
              practitioners or institutions (“Practitioner(s)”, “you” or
              “User”); or
            </p>
            <p>
              ii. A patient, his/her representatives or affiliates, searching
              for Practitioners through the application (“End-User”, “you” or
              “User”); or
            </p>
            <p>
              iii. Otherwise a user of the Mobile application (“you” or “User”).
            </p>
            <p>
              This Agreement applies to those services made available by IMAGe
              on the Mobile application, which are offered free of charge to the
              Users (“Services”), including the following:
            </p>
            <p>
              iv. For Practitioners: Listing of Practitioners and their profiles
              and contact details, to be made available to the other Users and
              visitors to the Mobile application;
            </p>
            <p>
              v. For other Users: Facility to (i) create and maintain ‘Health
              Accounts’, (ii) search for Practitioners by name, specialty, and
              geographical area, or any other criteria that may be developed and
              made available by IMAGe, and (iii) to make appointments with
              Practitioners.
            </p>
            <p>
              The Services may change from time to time, at the sole discretion
              of IMAGe, and the Agreement will apply to your visit to and your
              use of the Mobile application to avail the Service, as well as to
              all information provided by you on the application at any given
              point in time.
            </p>
            <p>
              This Agreement defines the terms and conditions under which you
              are allowed to use the Mobile application and describes the manner
              in which we shall treat your account while you are registered as a
              member with us. If you have any questions about any part of the
              Agreement, feel free to contact us at
              indian.myeloma.academic.group@gmail.com.
            </p>
            <p>
              By downloading and accessing the Mobile application to use the
              Services, you irrevocably accept all the conditions stipulated in
              this Agreement, the Subscription Terms of Service and Privacy
              Policy, as available on the Mobile application, and agree to abide
              by them. This Agreement supersedes all previous oral and written
              terms and conditions (if any) communicated to you relating to your
              use of the Mobile application to avail the Services. By availing
              any Service, you signify your acceptance of the terms of this
              Agreement.
            </p>
            <p>
              We reserve the right to modify or terminate any portion of the
              Agreement for any reason and at any time, and such modifications
              shall be informed to you in writing. You should read the Agreement
              at regular intervals. Your use of the application following any
              such modification constitutes your agreement to follow and be
              bound by the Agreement so modified.
            </p>
            <p>
              You acknowledge that you will be bound by this Agreement for
              availing any of the Services offered by us. If you do not agree
              with any part of the Agreement, please do not use the Application
              or avail any Services.
            </p>
            <p>
              Your access to use of the Mobile application and the Services will
              be solely at the discretion of IMAGe.
            </p>
            <p>
              The Agreement is published in compliance of, and is governed by
              the provisions of Indian law, including but not limited to:
            </p>
            <p>vi. The Indian Contract Act, 1872,</p>
            <p>vii. The (Indian) Information Technology Act, 2000, and</p>
            <p>
              viii. The rules, regulations, guidelines and clarifications framed
              there under, including the (Indian) Information Technology
              (Reasonable Security Practices and Procedures and Sensitive
              Personal Information) Rules, 2011 (the “SPI Rules”), and the
              (Indian) Information Technology (Intermediaries Guidelines) Rules,
              2011 (the “IG Rules”).
            </p>
            <h3>2. CONDITIONS OF USE</h3>
            <p>
              You must be 18 years of age or older to register, use the
              Services, or visit or use the Mobile application in any manner. By
              registering and using the Mobile application or accepting this
              Agreement, you
            </p>
            <p>
              represent and warrant to IMAGe that you are 18 years of age or
              older, and that you have the right, authority and capacity to use
              the Mobile application and the Services available through the
              Application, and agree to and abide by this Agreement.
            </p>
            <h3>
              3. TERMS OF USE APPLICABLE TO ALL USERS OTHER THAN PRACTITIONERS
            </h3>
            <p>
              The terms in this Clause 3 are applicable only to Users other than
              Practitioners.
            </p>
            <h3 style={{ fontWeight: 'normal' }}>
              3.1 END-USER ACCOUNT AND DATA PRIVACY
            </h3>
            <p>
              3.1.1 The terms “personal information” and “sensitive personal
              data or information” are defined under the SPI Rules, and are
              reproduced in the Privacy Policy.
            </p>
            <p>
              3.1.2 IMAGe may by its Services, collect information relating to
              the devices through which you access the Mobile application, and
              anonymous data of your usage. The collected information will be
              used only for improving the quality of IMAGe’s services and to
              build new services.
            </p>
            <p>
              3.1.3 The Mobile application allows IMAGe to have access to
              registered Users’ personal email or phone number, for
              communication purpose so as to provide you a better way of booking
              appointments and for obtaining feedback in relation to the
              Practitioners and their practice.
            </p>
            <p>3.1.4 The Privacy Policy sets out, inter-alia:</p>
            <p>
              i.The type of information collected from Users, including
              sensitive personal data or information;
            </p>
            <p>ii.The purpose, means and modes of usage of such information;</p>
            <p>
              iii.How and to whom IMAGe will disclose such information; and,
            </p>
            <p>iv. Other information mandated by the SPI Rules.</p>
            <p>
              3.1.5 The User is expected to read and understand the Privacy
              Policy, so as to ensure that he or she has the knowledge of,
              inter-alia:
            </p>
            <p>i.the fact that certain information is being collected;</p>
            <p>ii.the purpose for which the information is being collected;</p>
            <p>iii.the intended recipients of the information;</p>
            <p>iv.the nature of collection and retention of the information;</p>
            <p>
              v.the various rights available to such Users in respect of such
              information.
            </p>
            <p>
              3.1.6 IMAGe shall not be responsible in any manner for the
              authenticity of the personal information or sensitive personal
              data or information supplied by the User to IMAGe or to any other
              person acting on behalf of IMAGe.
            </p>
            <p>
              3.1.7 The User is responsible for maintaining the confidentiality
              of the User’s account access information and password, if the User
              is registered on the Mobile application. The User shall be
              responsible for all usage of the User’s account and password,
              whether or not authorized by the User. The User shall immediately
              notify IMAGe of any actual or suspected unauthorized use of the
              User’s account or password. Although IMAGe will not be liable for
              your losses caused by any unauthorized use of your account, you
              may be liable for the losses of IMAGe or such other parties as the
              case may be, due to any unauthorized use of your account.
            </p>
            <p>
              3.1.8 If a User provides any information that is untrue,
              inaccurate, not current or incomplete (or becomes untrue,
              inaccurate, not current or incomplete), or IMAGe has reasonable
              grounds to suspect that such information is untrue, inaccurate,
              not current or incomplete, IMAGe has the right to discontinue the
              Services to the User at its sole discretion.
            </p>
            <p>
              3.1.9 IMAGe may use such information collected from the Users from
              time to time for the purposes of debugging patient support related
              issues.
            </p>
            <h3 style={{ fontWeight: 'normal' }}>3.2 RELEVANCE ALGORITHM</h3>
            <p>
              IMAGe’s relevance algorithm for the Practitioners is a fully
              automated system that lists the Practitioners, their profile and
              information regarding their Practice on its Mobile application.
              These listings of Practitioners do not represent any fixed
              objective ranking or endorsement by IMAGe. IMAGe will not be
              liable for any change in the relevance of the Practitioners on
              search results, which may take place from time to time. The
              listing of Practitioners will be based on automated computation of
              the various factors including inputs made by the Users including
              their comments and feedback. Such factors may change from time to
              time, in order to improve the listing algorithm. IMAGe in no event
              will be held responsible for the accuracy and the relevancy of the
              listing order of the Practitioners on the Mobile application.
            </p>
            <h3 style={{ fontWeight: 'normal' }}>
              3.3. LISTING CONTENT AND DISSEMINATING INFORMATION
            </h3>
            <p>
              3.3.1 IMAGe collects, directly or indirectly, and displays on the
              Mobile application, relevant information regarding the profile and
              practice of the Practitioners listed on the Mobile application,
              such as their specialization, qualification, location, visiting
              hours, and similar details. IMAGe takes reasonable efforts to
              ensure that such information is updated at frequent intervals.
              Although IMAGe
            </p>
            <p>
              screens and vets the information and photos submitted by the
              Practitioners, it cannot be held liable for any inaccuracies or
              incompleteness represented from it, despite such reasonable
              efforts.
            </p>
            <p>
              3.3.2 The Services provided by IMAGe or any of its licensors or
              service providers are provided on an
            </p>
            <p>
              "as is" and “as available’ basis, and without any warranties or
              conditions (express or implied, including the implied warranties
              of merchantability, accuracy, fitness for a particular purpose,
              title and non-infringement, arising by statute or otherwise in law
              or from a course of dealing. IMAGe does not provide or make any
              representation, warranty or guarantee, express or implied about
              the Mobile application or the Services. IMAGe does not guarantee
              the accuracy or completeness of any content or information
              provided by Users on the Mobile application. To the fullest extent
              permitted by law,
            </p>
            <p>
              IMAGe disclaims all liability arising out of the User’s use or
              reliance upon the Mobile application, the Services,
              representations and warranties made by other Users, the content or
              information provided by the Users on the Mobile application, or
              any opinion or suggestion given or expressed by IMAGe or any User
              in relation to any User or services provided by such User.
            </p>
            <p>
              3.3.3 The Mobile application may be linked to the website of third
              parties, affiliates and business partners. IMAGe has no control
              over, and not liable or responsible for content, accuracy,
              validity, reliability, quality of such websites or made available
              by/through our Mobile application. Inclusion of any link on the
              Mobile application does not imply that IMAGe endorses the linked
              site. User may use the links and these services at User’s own
              risk.
            </p>
            <p>
              3.3.4 IMAGe assumes no responsibility, and shall not be liable
              for, any damages to, or viruses that may infect User’s equipment
              on account of User’s access to, use of the Mobile application or
              the downloading of any material, data, text, images, video
              content, or audio content from the Mobile application. If a User
              is dissatisfied with the Mobile application, User’s sole remedy is
              to discontinue using the Mobile application.
            </p>
            <p>
              3.3.5 If IMAGe determines that you have provided fraudulent,
              inaccurate, or incomplete information, including through feedback,
              IMAGe reserves the right to immediately suspend your access to the
              Mobile application or any of your accounts with IMAGe and makes
              such declaration on the Mobile application alongside your
              name/your clinic’s name as determined by IMAGe. You shall be
              liable to indemnify IMAGe for any losses incurred as a result of
              your misrepresentations or fraudulent feedback that has adversely
              affected IMAGe or its Users.
            </p>
            <h3 style={{ fontWeight: 'normal' }}>
              3.4 BOOK APPOINTMENT AND CALL FACILITY
            </h3>
            <p>
              IMAGe enables Users to connect with Practitioners through two
              methods: a) Book facility that allows Users book an appointment
              through the Mobile application; b) Value added telephonic services
              which connect Users directly to the Practitioner’s number provided
              on the Mobile application.
            </p>
            <p>
              3.4.1 IMAGe will ensure Users are provided confirmed appointment
              on the Book facility. However, IMAGe has no liability if such an
              appointment is later cancelled by the Practitioner, or the same
              Practitioner is not available for appointment.
            </p>
            <p>
              3.4.2 If a User has utilized the telephonic services, IMAGe
              reserves the right to share the information provided by the User
              with the Practitioner and store such information and/or
              conversation of the User with the Practitioner, in accordance with
              our Privacy Policy.
            </p>
            <p>
              3.4.3 The results of any search Users perform on the Mobile
              application for Practitioners should not be construed as an
              endorsement by IMAGe of any such particular Practitioner. If the
              User decides to engage with a Practitioner to seek medical
              services, the User shall be doing so at his/her own risk.
            </p>
            <p>
              3.4.4 Without prejudice to the generality of the above, IMAGe is
              not involved in providing any healthcare or medical advice or
              diagnosis and hence is not responsible for any interactions
              between User and the Practitioner. User understands and agrees
              that IMAGe will not be liable for:
            </p>
            <p>
              i.User interactions and associated issues User has with the
              Practitioner;
            </p>
            <p>
              ii.The ability or intent of the Practitioner(s) or the lack of it,
              in fulfilling their obligations towards Users;
            </p>
            <p>
              iii.Any wrong medication or quality of treatment being given by
              the Practitioner(s), or any medical negligence on part of the
              Practitioner(s);
            </p>
            <p>
              iv.Inappropriate treatment, or similar difficulties or any type of
              inconvenience suffered by the User due to a failure on the part of
              the Practitioner to provide agreed Services;
            </p>
            <p>
              v. Any misconduct or inappropriate behavior by the Practitioner or
              the Practitioner’s staff;
            </p>
            <p>
              3.4.5 Users are allowed to provide feedback about their
              experiences with the Practitioner, however, the User shall ensure
              that, the same is provided in accordance with applicable law. User
              however understands that, IMAGe shall not be obliged to act in
              such manner as may be required to give effect to the content of
              Users feedback, such as suggestions for delisting of a particular
              Practitioner from the Mobile application.
            </p>
            <h3 style={{ fontWeight: 'normal' }}>
              3.5 NO DOCTOR-PATIENT RELATIONSHIP; NOT FOR EMERGENCY USE
            </h3>
            <p>
              3.5.1 Please note that some of the content, text, data, graphics,
              images, information, suggestions, guidance, and other material
              (collectively, “Information”) that may be available on the Mobile
              application (including information provided in direct response to
              your questions or postings) may be provided by individuals in the
              medical profession. The provision of such Information does not
              create a licensed medical professional/patient relationship,
              between IMAGe and you and does not constitute an opinion, medical
              advice, or diagnosis or treatment of any particular condition, but
              is only provided to assist you with locating appropriate medical
              care from a qualified practitioner.
            </p>
            <p>
              3.5.2 It is hereby expressly clarified that, the Information that
              you obtain or receive from IMAGe, and its employees, contractors,
              partners, sponsors, advertisers, licensors or otherwise on the
              Mobile application is for informational purposes only. We make no
              guarantees, representations or warranties, whether expressed or
              implied, with respect to professional qualifications, quality of
              work, expertise or other information provided on the Mobile
              application. In no event shall we be liable to you or anyone else
              for any decision made or action taken by you in reliance on such
              information.
            </p>
            <p>
              3.5.3 The Services are not intended to be a substitute for getting
              in touch with emergency healthcare.
            </p>
            <p>
              If you are an End-User facing a medical emergency (either on your
              or a another person’s behalf), please contact an ambulance service
              or hospital directly.
            </p>
            <h3 style={{ fontWeight: 'normal' }}>3.6 IMAGE CONSULT</h3>
            <p>3.6.1 Definition.</p>
            <p>
              Consult is a feature owned and provided by IMAGe that allows Users
              & Practitioners to communicate, either on unpaid mode or on paid
              mode, depending on the option chosen by the User. Further, Users
              may access this feature on care4myeloma platform to get assigned,
              for the purposes of consultation, to a Practitioner whereby such
              Practitioners are, by default, assigned through the system’s
              algorithm/software-program that finds the most available and
              accepting Practitioner or Users may choose Practitioners of choice
              through the search options made available on care4myeloma Mobile
              application. The scope of this feature as detailed herein is
              collectively referred to as "Consult".
            </p>
            <p>3.6.2 Terms for Users:</p>
            <p>
              The Users expressly understand, acknowledge and agree to the
              following set forth herein below:
            </p>
            <p>
              i.In the event the Users intend to consult a specific Practitioner
              of choice, the same is facilitated through search options as made
              available on care4myeloma Mobile application. In cases where Users
              cannot choose a Practitioner (due to system setup), the system
              uses an algorithm/software- program to find the most available and
              accepting Practitioner.
            </p>
            <p>
              ii.In case any prescription is being provided to User by the
              Practitioner, the same is being provided basis the online
              consultation, however it may vary when examined in person, hence
              in no event shall the prescription provided by Practitioners be
              relied as a final and conclusive solution.
            </p>
            <p>
              iii.The Users agree to use the advice from Practitioner on the
              Mobile application pursuant to:
            </p>
            <p>a) An ongoing treatment with their medical practitioner;</p>
            <p>
              b) A condition which does not require emergency treatment,
              physical examination or medical attention;
            </p>
            <p>
              c) Medical history available as records with them for reference;
            </p>
            <p>
              d) A record of physical examination and report thereof with them,
              generated through their local medical practitioner;
            </p>
            <p>
              e) Consultation with their medical practitioner before abandoning
              or modifying their ongoing treatment.
            </p>
            <p>
              i. In the event the Users intend to consult a specific
              Practitioner of choice, the same is facilitated through search
              options as made available on care4myeloma Mobile application. In
              cases where Users cannot choose a Practitioner (due to system
              setup), the system uses an algorithm/software- program to find the
              most available and accepting Practitioner.
            </p>
            <p>
              ii. In case any prescription is being provided to User by the
              Practitioner, the same is being provided basis the online
              consultation, however it may vary when examined in person, hence
              in no event shall the prescription provided by Practitioners be
              relied as a final and conclusive solution.
            </p>
            <p>
              iii. The Users agree to use the advice from Practitioner on the
              Mobile application pursuant to:
            </p>
            <p>a) An ongoing treatment with their medical practitioner;</p>
            <p>
              b) A condition which does not require emergency treatment,
              physical examination or medical attention;
            </p>
            <p>
              c) Medical history available as records with them for reference;
            </p>
            <p>
              d) A record of physical examination and report thereof with them,
              generated through their local medical practitioner;
            </p>
            <p>
              e) Consultation with their medical practitioner before abandoning
              or modifying their ongoing treatment.
            </p>
            <p>
              iv. The User agrees that by using Consult, the Practitioners on
              Consult will not be conducting physical examination of the Users,
              hence they may not have or be able to derive important information
              that is usually obtained through a physical examination. User
              acknowledges and agrees that the User is aware of this limitation
              and agrees to assume the complete risk of this limitation.
            </p>
            <p>
              v. The User understands that Consult shall not form a substitute
              for treatment that otherwise needs physical examination/immediate
              consultation. Further, the User understands that the advice
              provided by the Practitioner is based on general medical
              conditions and practices prevalent in India, to the best of his
              knowledge and ability, and not for conditions which are territory
              specific for regions other than India, irrespective of where the
              User is procuring medical services or engaging in communication
              with the Practitioner.
            </p>
            <p>
              vi. During the consultation and thereafter, the Practitioner may
              upload the prescription/health records of the User on the account
              of the User for access of the User. However, it is expressly
            </p>

            <p>
              clarified that for Users not located within India and using
              Consult, the Practitioner may or may not issue a prescription, at
              his sole discretion.
            </p>
            <p>
              vii. Notwithstanding anything contained herein, IMAGe in no manner
              endorses any Practitioner(s) that Users consult and is not in any
              manner responsible for any drug/medicines prescribed or the
              therapy prescribed by the Practitioner.
            </p>
            <p>
              viii. If Practitioner responds to the User’s query, the system
              could trigger communications to the User, in the form of
              notification/text/email/others. The User further understands that
              IMAGe may send such communications like text messages/email/calls
              before and/or after Practitioner’s consultation (physical or
              online) to User’s mobile number which is provided by Practitioner,
              based on the
            </p>
            <p>
              Practitioner’s settings. However and notwithstanding anything to
              the contrary in this Agreement,
            </p>
            <p>
              IMAGe does not take responsibility for timeliness of such
              communications.
            </p>
            <p>
              ix. Consult, is merely a consulting model, any interactions and
              associated issues with the Practitioner on Consult including but
              not limited to the User’s health issues and/or the User’s
              experiences is strictly between the User and the Practitioner. The
              User shall not hold IMAGe responsible for any such interactions
              and associated issues.
            </p>
            <p>
              x. The User hereby grants consent to IMAGe to feature certain of
              Users queries posted free of cost and respective Practitioners’
              responses as posted by the User on IMAGe Consult. Users further
              agree that any such information provided by the User will be
              subject to IMAGe Privacy Policy.
            </p>
            <p>
              xi. Any conversations that the Users have had with the
              Practitioner will be retained in IMAGe database as per the
              applicable laws and subject to confidentiality.
            </p>
            <p>
              xii. User understands and agrees to provide accurate information,
              and will not use this platform for any acts that are considered to
              be illegal in nature.
            </p>
            <p>
              xiii. If User decides to engage with a Practitioner to procure
              medical services or engages in communication, exchange of money
              for services outside of Consult platform, User shall do so at
              their own risk. IMAGe shall not be responsible for any breach of
              service or service deficiency by any Practitioner.
            </p>
            <p>
              xiv. The User agrees and understands that the transaction with the
              Practitioner are subject to jurisdiction of Indian laws and that
              any claim, dispute or difference arising from it shall be subject
              to the jurisdiction provision as contained in the Terms and
              Conditions hereunder, at all times. The
            </p>

            <p>
              User further agrees and understands that the Practitioner is a
              medical practitioner who is licensed to practice medicine in India
              and the onus is on the User to determine if he/she is eligible to
              consult with the Practitioners via the Mobile application. It is
              expressly clarified that at no point in time can it be construed
              that the Practitioner is practicing medicine in a territory other
              than India, irrespective of where the User is located and procures
              medical services or engages in communication with the
              Practitioner, in any manner whatsoever.
            </p>
            <p>
              xv The User shall indemnify and hold harmless IMAGe and its
              affiliates, directors, officers, employees and agents from and
              against any and all claims, proceedings, penalties, damages, loss,
              liability, actions, costs and expenses (including but not limited
              to court fees and attorney fees) arising due to or in relation to
              the use of Mobile application by the User, by breach of the Terms
              or violation of any law, rules or regulations by the User, or due
              to such other actions, omissions or commissions of the User that
              gave rise to the claim.
            </p>
            <h3 style={{ fontWeight: 'normal' }}>3.6.4 Express Disclaimers:</h3>
            <p>
              i. Consult is intended for general purposes only and is not meant
              to be used in emergencies/serious illnesses requiring physical
              consultation. Further, if the Practitioner adjudges that a
              physical examination would be required and advises{' '}
              <nobr>‘in-person</nobr> consultation’, it is the sole
              responsibility of the User, to book an appointment for physical
              examination and <nobr>in-person</nobr> consultation whether the
              same is with the Practitioner listed on the Mobile application or
              otherwise. In case of any negligence on the part of the User in
              acting on the same and the condition of the User deteriorates,
              IMAGe shall not be held liable.
            </p>
            <p>
              ii. Consult is a platform being made available to Users to assist
              them to obtain consultation from Practitioners and does not intend
              to replace the physical consultation with the Practitioner.
            </p>
            <h3 style={{ fontWeight: 'normal' }}>
              3.6.5 Terms for Practitioners:
            </h3>
            <p>
              i. The Practitioner shall promptly reply to the User after
              receiving User’s communication. In case of{' '}
              <nobr>non-compliance</nobr> with regard to adhering to the
              applicable laws/rules/regulations/guidelines by the Practitioner,
              IMAGe shall have the right to replace such Practitioners for the
              purpose of consultation to the User or remove such Practitioners
              from the platform/IMAGe application/site.
            </p>
            <p>
              ii. The Practitioner understands and agrees that, IMAGe shall at
              its sole discretion, at any time be entitled to, show as other
              Practitioners available for consultation.
            </p>

            <p>
              iii. The Practitioner further understands that, there is a
              responsibility on the Practitioner to treat the User, pari passu,
              as the Practitioner would have otherwise treated the User on a
              physical
              <nobr>one-on-</nobr> one consultation model.
            </p>
            <p>
              iv. The Practitioner has the discretion to cancel any consultation
              at any point in time in cases where the Practitioner feels, it is
              beyond his/her expertise or his/her capacity to treat the User. In
              such cases, the User has the option of choosing other
              Practitioners. However, it is strongly recommended that the
              Practitioner advise the User and explain appropriately for next
              steps.
            </p>
            <p>
              v. The Practitioner shall at all times ensure that all the
              applicable laws that govern the Practitioner shall be followed and
              utmost care shall be taken in terms of the consultation being
              rendered.
            </p>
            <p>
              vi. The Practitioner acknowledges that should IMAGe find the
              Practitioner to be in violation of any of the applicable
              laws/rules/ regulations/guidelines set out by the authorities then
              IMAGe shall be entitled to cancel the consultation with such
              Practitioner or take such other legal action as may be required.
            </p>
            <p>
              vii. It is further understood by the Practitioner that the
              information that is disclosed by the User at the time of
              consultation is personal information and is subject to all
              applicable privacy laws, shall be confidential in nature and
              subject to User and Practitioner privilege.
            </p>
            <p>
              viii. The Practitioner understands that when a User books a{' '}
              <nobr>time-slot</nobr> with the Practitioner for online
              consultation, the Practitioner must comply with the time slot to
              the best of their availability. In case of delay, the doctor must
              notify User to their best possible ability.
            </p>
            <p>
              ix. The Practitioner understands that IMAGe makes no promise or
              guarantee for any uninterrupted communication and the Practitioner
              shall not hold IMAGe liable, if for any reason the communication
              is not delivered to the User(s), or are delivered late or not
              accessed, despite the efforts undertaken by IMAGe.
            </p>
            <p>
              x. It shall be the responsibility of the Practitioner to ensure
              that the information provided by User is accurate and not
              incomplete and understand that IMAGe shall not be liable for any
              errors in the information included in any communication between
              the Practitioner and User.
            </p>
            <p>
              xi. The Practitioner shall indemnify and hold harmless IMAGe and
              its affiliates, directors, officers, employees and agents from and
              against any and all claims, proceedings, penalties, damages, loss,
              liability, actions, costs and expenses (including but not limited
              to court fees and attorney fees)
            </p>

            <p>
              arising due to the services provided by Practitioner, violation of
              any law, rules or regulations by the Practitioner or due to such
              other actions, omissions or commissions of the Practitioner that
              gave rise to the claim.
            </p>
            <h3>3.7. RECORDS</h3>
            <p>
              IMAGe may provide <nobr>End-Users</nobr> with a free facility
              known as ‘Records’ on its mobile application ‘care4myeloma’.
              Information available in your Records is of two types:
            </p>
            <p>
              i.
              <nobr>User-created:</nobr>
              Information uploaded by you.
            </p>
            <p>
              ii.
              <nobr>Practice-created:</nobr>
              Health Records generated by your interaction with a Practitioner.
            </p>
            <p>
              The specific terms relating to such Health Account are as below,
              without prejudice to the rest of these Terms and the Privacy
              Policy:
            </p>
            <p>
              3.7.1. Your Records is only created after you have signed up and
              explicitly accepted these Terms.
            </p>
            <p>
              <b>3.7.2</b> Any Practice created Health Record is provided on an{' '}
              <nobr>as-is</nobr> basis at the sole intent, risk and
              responsibility of the Practitioner. You should contact the
              relevant Practitioner in case you wish to point
            </p>
            <p>
              out any discrepancies or add, delete, or modify the Health Record
              in any manner.
            </p>
            <p>
              3.7.3. The Health Records are provided on an <nobr>as-is</nobr>{' '}
              basis. While we strive to maintain the highest levels of service
              availability, IMAGe is not liable for any interruption that may be
              caused to your access of the Services.
            </p>
            <p>
              3.7.4 The reminder provided by the application is only a
              supplementary way of reminding you to perform your activities as
              prescribed by your Practitioner. In the event of any medicine
              reminders provided by IMAGe, you should refer to your prescription
              before taking any medicines. IMAGe is not liable if for any reason
              reminders are not delivered to you or are delivered late or
              delivered incorrectly,
            </p>
            <p>
              despite its best efforts. In case you do not wish to receive the
              reminders, you can switch it off through the care4myeloma app.
            </p>
            <p>
              3.7.5. It is your responsibility to keep your correct mobile
              number and email ID updated in the Records. Every time you change
              any contact information (mobile or email), we will send a
              confirmation. IMAGe is not responsible for any loss or
              inconvenience caused due to your failure in updating the contact
              details with IMAGe.
            </p>

            <p class='p121 ft13'>
              3.7.6. IMAGe uses <nobr>industry–level</nobr> security and
              encryption to your Health Records. However, IMAGe does not
              guarantee to prevent unauthorized access if you lose your login
              credentials or they are otherwise compromised. In the event you
              are aware of any unauthorized use or access, you shall immediately
            </p>
            <p class='p122 ft19'>
              inform IMAGe of such unauthorized use or access. Please safeguard
              your login credentials and report any actual suspected breach of
              account to indian.myeloma.academic.group@gmail.com.
            </p>
            <p class='p123 ft9'>
              3.7.7. You may lose your “User created” record, if the data is not
              synced with the server.
            </p>
            <p class='p124 ft9'>
              3.7.8. If the Health Record is unassessed for a stipulated time,
              you may not be able to access your Health
            </p>
            <p class='p125 ft9'>Records due to security reasons.</p>
            <p class='p124 ft9'>
              3.7.9. IMAGe is not liable if for any reason, Health Records are
              not delivered to you or are delivered late
            </p>
            <p class='p125 ft9'>despite its best efforts.</p>
            <p class='p126 ft9'>
              3.10.10. IMAGe is not responsible or liable for any content, fact,
              Health Records, medical deduction or
            </p>
            <p class='p127 ft3'>
              the language used in your Health Records whatsoever. Your
              Practitioner is solely responsible and liable for your Health
              Records and any information provided to us including but not
              limited to the content in them.
            </p>
            <p class='p128 ft16'>
              3.10.11. IMAGe has the ability in its sole discretion to retract
              Health Records without any prior notice if they are found to be
              shared incorrectly or inadvertently.
            </p>
            <p class='p129 ft16'>
              3.10.12. IMAGe will follow the law of land in case of any
              constitutional court or jurisdiction mandates to share the Health
              Records for any reason.
            </p>
            <p class='p130 ft19'>
              3.10.13. You agree and acknowledge that IMAGe may need to access
              the Health Record for cases such as any technical or operational
              issue of the End User in access or ownership of the Records.
            </p>
            <h3>4. TERMS OF USE PRACTITIONERS</h3>
            <p>
              The terms in this Clause 4 are applicable only to Practitioners.
            </p>
            <h3 style={{ fontWeight: 'normal' }}>4.1 LISTING POLICY</h3>
            <p class='p133 ft34'>
              4.1.1. IMAGe, directly and indirectly, collects information
              regarding the Practitioners’ profiles, contact details, and
              practice. IMAGe reserves the right to take down any Practitioner’s
              profile as well as the right to display the profile of the
              Practitioners, with or without notice to the concerned
              Practitioner. This information is collected for the purpose of
              facilitating interaction with the <nobr>End-Users</nobr> and other
              Users. If any information displayed on the Mobile application in
              connection with you and your profile is found to be incorrect, you
              are required to inform IMAGe immediately to enable IMAGe to make
              the necessary amendments.
            </p>

            <p class='p121 ft13'>
              <b>3.7.6</b>
              IMAGe uses <nobr>industry–level</nobr> security and encryption to
              your Health Records. However, IMAGe does not guarantee to prevent
              unauthorized access if you lose your login credentials or they are
              otherwise compromised. In the event you are aware of any
              unauthorized use or access, you shall immediately
            </p>
            <p class='p122 ft19'>
              inform IMAGe of such unauthorized use or access. Please safeguard
              your login credentials and report any actual suspected breach of
              account to indian.myeloma.academic.group@gmail.com.
            </p>
            <p class='p123 ft9'>
              <b>3.7.7</b>
              You may lose your “User created” record, if the data is not synced
              with the server.
            </p>
            <p class='p124 ft9'>
              <b>3.7.8</b>
              If the Health Record is unassessed for a stipulated time, you may
              not be able to access your Health
            </p>
            <p class='p125 ft9'>Records due to security reasons.</p>
            <p class='p124 ft9'>
              <b>3.7.9</b>
              IMAGe is not liable if for any reason, Health Records are not
              delivered to you or are delivered late
            </p>
            <p class='p125 ft9'>despite its best efforts.</p>
            <p class='p126 ft9'>
              <b>3.10.10</b>
              IMAGe is not responsible or liable for any content, fact, Health
              Records, medical deduction or
            </p>
            <p class='p127 ft3'>
              the language used in your Health Records whatsoever. Your
              Practitioner is solely responsible and liable for your Health
              Records and any information provided to us including but not
              limited to the content in them.
            </p>
            <p class='p128 ft16'>
              <b>3.10.11</b>
              IMAGe has the ability in its sole discretion to retract Health
              Records without any prior notice if they are found to be shared
              incorrectly or inadvertently.
            </p>
            <p class='p129 ft16'>
              <b>3.10.12</b>
              IMAGe will follow the law of land in case of any constitutional
              court or jurisdiction mandates to share the Health Records for any
              reason.
            </p>
            <p class='p130 ft19'>
              <b>3.10.13</b>
              You agree and acknowledge that IMAGe may need to access the Health
              Record for cases such as any technical or operational issue of the
              End User in access or ownership of the Records.
            </p>
            <h3 class='p131 ft6'>
              <b>4. TERMS OF USE PRACTITIONERS</b>
            </h3>
            <p class='p26 ft9'>
              The terms in this Clause 4 are applicable only to Practitioners.
            </p>
            <p class='p132 ft9'>
              <b>4.1. </b>
              LISTING POLICY
            </p>
            <p class='p133 ft34'>
              <b>4.1.1. </b>
              IMAGe, directly and indirectly, collects information regarding the
              Practitioners’ profiles, contact details, and practice. IMAGe
              reserves the right to take down any Practitioner’s profile as well
              as the right to display the profile of the Practitioners, with or
              without notice to the concerned Practitioner. This information is
              collected for the purpose of facilitating interaction with the{' '}
              <nobr>End-Users</nobr> and other Users. If any information
              displayed on the Mobile application in connection with you and
              your profile is found to be incorrect, you are required to inform
              IMAGe immediately to enable IMAGe to make the necessary
              amendments.
            </p>

            <p class='p134 ft19'>
              <b>4.1.2 </b>
              IMAGe shall not be liable and responsible for the ranking of the
              Practitioners on external websites and search engines
            </p>
            <p class='p135 ft8'>
              <b>4.1.3 </b>
              IMAGe shall not be responsible or liable in any manner to the
              Users for any losses, damage, injuries or expenses incurred by the
              Users as a result of any disclosures or publications made by
              IMAGe, where the User has expressly or implicitly consented to the
              making of disclosures or publications by IMAGe. If the User had
              revoked such consent under the terms of the Privacy Policy, then
              IMAGe shall not be responsible or liable in any manner to the User
              for any losses, damage, injuries or expenses incurred by the User
              as a result of any disclosures made by IMAGe prior to its actual
              receipt of such revocation.
            </p>
            <p class='p136 ft19'>
              <b>4.1.4 </b>
              Practitioners explicitly agree that IMAGe reserves the right to
              publish the Content provided by Practitioners to a third party
              including content platforms.
            </p>
            <p class='p137 ft8'>
              <b>4.1.5 </b>
              You as a Practitioner hereby represent and warrant that you will
              use the Services in accordance with applicable law. Any
              contravention of applicable law as a result of your use of these
              Services is your sole responsibility, and IMAGe accepts no
              liability for the same.
            </p>
            <p class='p138 ft9'>
              <b>4.2 </b>
              PROFILE OWNERSHIP AND EDITING RIGHTS
            </p>
            <p class='p139 ft34'>
              IMAGe ensures easy access to the Practitioners to update your
              profile information. IMAGe reserves the right of ownership of all
              the Practitioner’s profile and photographs and to moderate the
              changes or updates requested by Practitioners. However, IMAGe
              takes the independent decision whether to publish or reject the
              requests submitted for the respective changes or updates. You
              hereby represent and warrant that you are fully entitled under law
              to upload all content uploaded by you as part of your profile or
              otherwise while using IMAGe’s services, and that no such content
              breaches any third party rights, including intellectual property
              rights. Upon becoming aware of a breach of the foregoing
              representation, IMAGe may modify or delete parts of your profile
              information at its sole discretion with or without notice to you.
            </p>
            <p class='p140 ft9'>
              <b>4.3 </b>
              REVIEWS AND FEEDBACK DISPLAY RIGHTS OF IMAGe
            </p>
            <p class='p141 ft36'>
              <b>4.3.1 </b>
              All Critical Content is content created by the Users of
              care4myeloma (“Mobile application”) and the clients of IMAGe
              customers and Practitioners, including the <nobr>End-Users.</nobr>
              As a platform, IMAGe does not take responsibility for Critical
              Content and its role with respect to Critical Content is
              restricted to that of an ‘intermediary’ under the Information
              Technology Act, 2000. The role of
            </p>
            <p class='p142 ft16'>
              IMAGe and other legal rights and obligations relating to the
              Critical Content are further detailed in Clauses 3.9 and 5 of
              these Terms.
            </p>

            <p class='p143 ft19'>
              <b>4.3.2 </b>
              IMAGe reserves the right to collect feedback and Critical Content
              for all the Practitioners and Clinics listed on the Mobile
              application.
            </p>
            <p class='p144 ft19'>
              <b>4.3.3 </b>
              IMAGe shall have no obligation to <nobr>pre-screen,</nobr> review,
              flag, filter, modify, refuse or remove any or all Critical Content
              from any Service, except as required by applicable law.
            </p>
            <p class='p145 ft32'>
              <b>4.3.4 </b>
              You understand that by using the Services you may be exposed to
              Critical Content or other content that you may find offensive or
              objectionable. IMAGe shall not be liable for any effect on
            </p>
            <p class='p146 ft8'>
              Practitioner’s business due to Critical Content of a negative
              nature. In these respects, you may use the Service at your own
              risk. IMAGe however, as an ‘intermediary, takes steps as required
              to comply with applicable law as regards the publication of
              Critical Content. The legal rights and obligations with respect to
              Critical Content and any other information sought to be published
              by Users are further detailed in Clauses 3.9 and 5 of these Terms.
            </p>
            <p class='p145 ft8'>
              <b>4.3.5 </b>
              IMAGe will take down information under standards consistent with
              applicable law, and shall in no circumstances be liable or
              responsible for Critical Content, which has been created by the
              Users. The principles set out in relation to third party content
              in the terms of Service for the Mobile application shall be
              applicable mutatis mutandis in relation to Critical Content posted
              on the Mobile application.
            </p>
            <p class='p145 ft8'>
              <b>4.3.6 </b>
              If IMAGe determines that you have provided inaccurate information
              or enabled fraudulent feedback, IMAGe reserves the right to
              immediately suspend any of your accounts with IMAGe and makes such
              declaration on the Mobile application alongside your name/your
              clinics name as determined by IMAGe.
            </p>
            <p class='p147 ft9'>
              <b>4.4 </b>
              RELEVANCE ALGORITHM
            </p>
            <p class='p148 ft8'>
              IMAGe has designed the relevance algorithm in the best interest of
              the <nobr>End-User</nobr> and may adjust the relevance algorithm
              from time to time to improve the quality of the results given to
              the patients. It is a pure merit driven, proprietary algorithm
              which cannot be altered for specific Practitioners. IMAGe shall
              not be liable for any effect on the Practitioner’s business
              interests due to the change in the Relevance Algorithm.
            </p>
            <h3 class='p149 ft6'>
              <b>5.RIGHTS AND OBLIGATIONS RELATING TO CONTENT</b>
            </h3>
            <p class='p150 ft22'>
              <b>5.1 </b>
              As mandated by Regulation 3(2) of the IG Rules, IMAGe hereby
              informs Users that they are not permitted to host, display,
              upload, modify, publish, transmit, update or share any information
              that:
            </p>

            <p class='p151 ft9'>
              i. belongs to another person and to which the User does not have
              any right to;
            </p>
            <p class='p152 ft13'>
              ii. is grossly harmful, harassing, blasphemous, defamatory,
              obscene, pornographic, pedophilic, libelous, invasive of another's
              privacy, hateful, or racially, ethnically objectionable,
              disparaging, relating or encouraging money laundering or gambling,
              or otherwise unlawful in any manner whatever;
            </p>
            <p class='p153 ft9'>iii. harm minors in any way;</p>
            <p class='p154 ft9'>
              iv. infringes any patent, trademark, copyright or other
              proprietary rights;
            </p>
            <p class='p27 ft9'>
              v. violates any law for the time being in force;
            </p>
            <p class='p155 ft16'>
              vi. deceives or misleads the addressee about the origin of such
              messages or communicates any information which is grossly
              offensive or menacing in nature;
            </p>
            <p class='p156 ft9'>vii. impersonate another person</p>
            <p class='p157 ft19'>
              viii. contains software viruses or any other computer code, files
              or programs designed to interrupt, destroy or limit the
              functionality of any computer resource;
            </p>
            <p class='p158 ft3'>
              ix. threatens the unity, integrity, defence, security or
              sovereignty of India, friendly relations with foreign states, or
              public order or causes incitement to the commission of any
              cognizable offence or prevents investigation of any offence or is
              insulting any other nation.
            </p>
            <p class='p159 ft9'>
              <b>5.2 </b>
              Users are also prohibited from:
            </p>

            <p class='p161 ft10'>
              violating or attempting to violate the integrity or security of
              the Mobile application or any IMAGe
            </p>

            <p class='p161 ft9'>Content</p>
            <p class='p163 ft19'>
              i. transmitting any information (including job posts, messages and
              hyperlinks) on or through the Mobile application that is
              disruptive or competitive to the provision of Services by IMAGe;
            </p>
            <p class='p164 ft9'>
              ii. intentionally submitting on the Mobile application any
              incomplete, false or inaccurate information;
            </p>
            <p class='p154 ft9'>
              iii. making any unsolicited communications to other Users;
            </p>
            <p class='p155 ft16'>
              iv. using any engine, software, tool, agent or other device or
              mechanism (such as spiders, robots, avatars or intelligent agents)
              to navigate or search the Mobile application;
            </p>
            <p class='p165 ft19'>
              v. attempting to decipher, decompile, disassemble or reverse
              engineer any part of the Mobile application;
            </p>
            <p class='p166 ft16'>
              vi. copying or duplicating in any manner any of the IMAGe Content
              or other information available from the Mobile application;
            </p>
            <p class='p167 ft9'>
              vii. framing or hot linking or deep linking any IMAGe Content.
            </p>
            <p class='p168 ft19'>
              viii. circumventing or disabling any digital rights management,
              usage rules, or other security features of the Software.
            </p>
            <p class='p169 ft36'>
              <b>5.3</b>
              IMAGe, upon obtaining knowledge by itself or been brought to
              actual knowledge by an affected person in writing or through email
              signed with electronic signature about any such information as
              mentioned above, shall be entitled to disable such information
              that is in contravention of Clauses 5.1 and 5.2. IMAGe shall also
              be entitled to preserve such information and associated records
              for at least
            </p>
            <p class='p170 ft9'>
              90 (ninety) days for production to governmental authorities for
              investigation purposes.
            </p>
            <p class='p171 ft3'>
              <b>5.4</b>
              In case of <nobr>non-compliance</nobr> with any applicable laws,
              rules or regulations, or the Agreement (including the Privacy
              Policy) by a User, IMAGe has the right to immediately terminate
              the access or usage rights of the User to the Mobile application
              and Services and to remove <nobr>non-compliant</nobr>
            </p>
            <p class='p125 ft9'>information from the Mobile application.</p>
            <p class='p172 ft36'>
              <b>5.5</b> IMAGe may disclose or transfer{' '}
              <nobr>User-generated</nobr> information to its affiliates or
              governmental authorities in such manner as permitted or required
              by applicable law, and you hereby consent to such transfer. The
              SPI Rules only permit IMAGe to transfer sensitive personal data or
              information including any information, to any other body corporate
              or a person in India, or located in any other country, that
              ensures the same level of data protection that is adhered to by
              IMAGe as provided for under the SPI Rules, only if such transfer
              is necessary for the performance of the lawful contract between
              IMAGe or any person on its behalf and the User or where the User
              has consented to data transfer.
            </p>
            <p class='p173 ft16'>
              IMAGe respects the intellectual property rights of others and we
              do not hold any responsibility for any violations of any
              intellectual property rights
            </p>
            <h3 class='p174 ft6'>
              <b>6.TERMINATION</b>
            </h3>
            <p class='p175 ft54'>
              <b>6.1</b>
              IMAGe reserves the right to suspend or terminate a User’s access
              to the Mobile application and the Services with or without notice
              and to exercise any other remedy available under law, in cases
              where,
            </p>
            <p class='p161 ft10'>
              Such User breaches any terms and conditions of the Agreement;
            </p>
            <p class='p176 ft9'>
              i. A third party reports violation of any of its right as a result
              of your use of the Services;
            </p>
            <p class='p27 ft9'>
              ii. IMAGe is unable to verify or authenticate any information
              provide to IMAGe by a User;
            </p>

            <p class='p177 ft16'>
              iii. IMAGe has reasonable grounds for suspecting any illegal,
              fraudulent or abusive activity on part of such User; or
            </p>
            <p class='p178 ft19'>
              iv. IMAGe believes in its sole discretion that User’s actions may
              cause legal liability for such User, other Users or for IMAGe or
              are contrary to the interests of the Mobile application.
            </p>
            <p class='p179 ft8'>
              <b>6.2 </b>
              Once temporarily suspended, indefinitely suspended or terminated,
              the User may not continue to use the Mobile application under the
              same account, a different account or <nobr>re-register</nobr>{' '}
              under a new account. On termination of an account due to the
              reasons mentioned herein, such User shall no longer have access to
              data, messages, files and other material kept on the Mobile
              application by such User. The User shall ensure that he/she/it has
              continuous backup of any medical services the User has rendered in
              order to comply with the User’s record keeping process and
              practices.
            </p>
            <p class='p180 ft6'>
              <b>7.LIMITATION OF LIABILITY</b>
            </p>
            <p class='p181 ft8'>
              In no event, including but not limited to negligence, shall IMAGe,
              or any of its directors, officers, employees, agents or content or
              service providers (collectively, the “Protected Entities”) be
              liable for any direct, indirect, special, incidental,
              consequential, exemplary or punitive damages arising from, or
              directly or indirectly related to, the use of, or the inability to
              use, the Mobile application or the content, materials and
              functions related thereto, the Services, User’s provision of
              information via the Mobile application, even if such Protected
              Entity has been advised of the possibility of such damages. In no
              event shall the Protected Entities be liable for:
            </p>
            <p class='p161 ft10'>
              provision of or failure to provide all or any service by
              Practitioners to End- Users contacted or managed
            </p>
            <p class='p162 ft52'>&nbsp;</p>
            <p class='p161 ft9'>through the Mobile application;</p>
            <p class='p182 ft16'>
              i. any content posted, transmitted, exchanged or received by or on
              behalf of any User or other person on or through the Mobile
              application;
            </p>
            <p class='p60 ft9'>
              ii. any unauthorized access to or alteration of your transmissions
              or data; or
            </p>
            <p class='p183 ft9'>
              iii. any other matter relating to the Mobile application or the
              Service.
            </p>
            <h3 class='p184 ft6'>
              <b>8.RETENTION AND REMOVAL</b>
            </h3>
            <p class='p185 ft54'>
              IMAGe may retain such information collected from Users from its
              database for as long as necessary, depending on the type of
              information; purpose, means and modes of usage of such
              information; and according to the SPI Rules. Computer web server
              logs may be preserved as long as administratively necessary.
            </p>
            <h3 class='p174 ft6'>
              <b>9.APPLICABLE LAW AND DISPUTE SETTLEMENT</b>
            </h3>
            <p class='p130 ft19'>
              <b>9.1 </b>
              You agree that this Agreement and any contractual obligation
              between IMAGe and User will be governed by the laws of India.
            </p>
            <p class='p118 ft36'>
              <b>9.2 </b>
              Any dispute, claim or controversy arising out of or relating to
              this Agreement, including the determination of the scope or
              applicability of this Agreement to arbitrate, or your use of the
              Mobile application or the Services or information to which it
              gives access, shall be determined by arbitration in India, before
              a sole arbitrator appointed by IMAGe. Arbitration shall be
              conducted in accordance with the Arbitration and Conciliation Act,
              1996. The seat of such arbitration shall be New Delhi. All
              proceedings of such arbitration, including, without limitation,
              any awards, shall be in the English
            </p>
            <p class='p187 ft9'>
              language. The award shall be final and binding on the parties to
              the dispute.
            </p>
            <p class='p171 ft3'>
              <b>9.3 </b>
              Subject to the above Clause 9.2, the courts at Bengaluru shall
              have exclusive jurisdiction over any disputes arising out of or in
              relation to this Agreement, your use of the Mobile application or
              the Services or the information to which it gives access.
            </p>
            <h3 class='p188 ft6'>
              <b>10.CONTACT INFORMATION GRIEVANCE OFFICER</b>
            </h3>
            <p class='p189 ft9'>
              <b>10.1 </b>
              If a User has any questions concerning care4myeloma, the Mobile
              application, this Agreement,
            </p>
            <p class='p190 ft19'>
              the Services, or anything related to any of the foregoing, IMAGe
              customer support can be reached at the following email address:
              indian.myeloma.academic.group@gmail.com.
            </p>
            <p class='p118 ft36'>
              <b>10.2 </b>
              In accordance with the Information Technology Act, 2000, and the
              rules made there under, if you have any grievance with respect to
              the Mobile application or the service, including any discrepancies
              and grievances with respect to processing of information, you can
              contact our Grievance Officer at: Address: Army Hospital (Research
              &amp; Referral), Dhaula Kuan, New Delhi - 110010. Email:
              indian.myeloma.academic.group@gmail.com Telephone: +91 11 -
              25699830 (Ask to be connected to the Grievance Officer) In the
              event you suffer as a result of access or usage of our Mobile
              application by any person in violation of Rule 3 of the IG Rules,
              please address your grievance to the above person.
            </p>
            <h3 lass='p25 ft6'>
              <b>11.SEVERABILITY</b>
            </h3>
            <p class='p191 ft36'>
              If any provision of the Agreement is held by a court of competent
              jurisdiction or arbitral tribunal to be unenforceable under
              applicable law, then such provision shall be excluded from this
              Agreement and the remainder of the Agreement shall be interpreted
              as if such provision were so excluded and shall be enforceable in
              accordance with its terms; provided however that, in such event,
              the Agreement
            </p>

            <p class='p24 ft3'>
              shall be interpreted so as to give effect, to the greatest extent
              consistent with and permitted by applicable law, to the meaning
              and intention of the excluded provision as determined by such
              court of competent jurisdiction or arbitral tribunal.
            </p>
            <p class='p25 ft6'>
              <b>12.WAIVER</b>
            </p>
            <p class='p192 ft8'>
              No provision of this Agreement shall be deemed to be waived and no
              breach excused, unless such waiver or consent shall be in writing
              and signed by IMAGe. Any consent by IMAGe to, or a waiver by IMAGe
              of any breach by you, whether expressed or implied, shall not
              constitute consent to, waiver of, or excuse for any other
              different or subsequent breach.
            </p>
            <p class='p74 ft9'>
              YOU HAVE READ THESE TERMS OF USE AND AGREE TO ALL OF THE
              PROVISIONS CONTAINED ABOVE
            </p>
          </Typography>
        </Grid>
      </Grid>
    </Box>
    <Footer position='' />
    </>
  );
}

export default Terms;
