import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { TextField } from 'formik-material-ui';
import { withSnackbar } from 'notistack';
import api from '../../services/api';
import Footer from './Footer';
import Checkbox from 'components/formFields/Checkbox';
import { useHistory } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import Terms from './terms.pdf';
import Privacy from './privacy.pdf';
import logo from './mainlogo.png';
import CountUp from 'react-countup';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    width: '200px',
    height: '200px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  paper: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const signInSchema = yup.object({
  email: yup.string().required('Email is required'),
  password: yup.string().required('Enter password here'),
  terms: yup
    .bool()
    .test(
      'consent',
      'You have to agree with our Terms and Conditions!',
      value => value === true
    )
    .required('You have to agree with our Terms and Conditions!')
});
signInSchema
  .isValid({
    terms: true
  })
  .then(valid => valid);

function Welcome(props) {
  const [hostpitals, setHospitals] = useState([]);
  const history = useHistory();
  let terms = (
    <Typography>
      <a
        href={Terms}
        style={{
          color: '#007bff',
          textDecoration: 'none'
        }}
        target='_blank'>
        Terms & Conditions
      </a>
      &nbsp;and &nbsp;
      <a
        href={Privacy}
        style={{
          color: '#007bff',
          textDecoration: 'none'
        }}
        target='_blank'>
        Privacy Policy
      </a>
    </Typography>
  );

  useEffect(() => {
    api
      .getHospitals()
      .then(res => {
        setHospitals(res.data);
        console.log(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  const classes = useStyles();
  return (
    <>
      <Box pb={16}>
        <Grid container>
          <Grid item xs={8}>
            <Box p={4} pt={10}>
              <Grid container style={{textAlign:'center   '}} spacing={5}>
                {hostpitals.map((item, key) => (
                  <Grid item xs={2}>
                    <img width='60%' src={item.logo} alt={item.hospital_name} />{' '}
                    <br />
                    {item.hospital_name}
                    <br /> <br />
                    <div>
                      <Typography variant='h5' color="secondary" >
                        <CountUp end={item.no_of_patients} />
                      </Typography>

                      {/* {"No of Pateints"} */}
                    </div>
                  </Grid>

                ))}
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Box p={10}>
              <Paper className={classes.paper} elevation={6}>
                <Box textAlign='center'>
                  <img src={logo} width='40%' alt='' />
                </Box>
                <Typography component='h1' variant='h5'>
                  Sign in
                </Typography>

                <Formik
                  initialValues={{
                    email: '',
                    password: '',
                    remember: '',
                    terms: ''
                  }}
                  validationSchema={signInSchema}
                  onSubmit={async (values, { setSubmitting }) => {
                    if (values.remember == true) {
                      document.cookie = `email=${values.email}; password=${values.password}`;
                      localStorage.setItem('email', values.email);
                      localStorage.setItem('password', values.password);
                    }
                    try {
                      const response = await api.hospitalLogin(values);
                      localStorage.setItem(
                        'token',
                        `Token ${response.data.token}`
                      );
                      localStorage.setItem(
                        'hospital_id',
                        `${response.data.hospital_id}`
                      );
                      localStorage.setItem(
                        'hospital_name',
                        `${response.data.hospital_name}`
                      );
                      window.location.href = '/';
                    } catch (error) {
                      setSubmitting(false);
                      props.enqueueSnackbar('Invalid email or password', {
                        variant: 'error'
                      });
                    }
                  }}
                  render={({ values, isSubmitting }) => (
                    <Form className={classes.form}>
                      <Field
                        variant='outlined'
                        margin='dense'
                        fullWidth
                        label='Email Address'
                        name='email'
                        component={TextField}
                      />

                      <Field
                        variant='outlined'
                        margin='dense'
                        fullWidth
                        label='Password'
                        name='password'
                        type='password'
                        component={TextField}
                      />
                      <Box pt={2}>
                        <Checkbox name='remember' label='Remember Me' />
                        <Checkbox name='terms' label={terms} />
                      </Box>

                      <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        color='primary'
                        disabled={isSubmitting}
                        className={classes.submit}>
                        Sign In
                      </Button>
                    </Form>
                  )}
                />
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footer position='fixed' />
    </>
  );
}

export default withSnackbar(Welcome);
