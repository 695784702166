import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { useField } from 'formik';
import { Typography, Box } from '@material-ui/core';

function MyCheckbox({ label, ...props }) {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';

  return (
    <Box mt={props.margin}>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={field.value}
              onChange={props.onChange}
              {...field}
              {...props}
            />
          }
          label={label}
        />
        <Typography color='error' variant='caption'>
          {errorText}
        </Typography>
      </FormGroup>
    </Box>
  );
}

export default MyCheckbox;
