import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import nda from './NDA.pdf';
import terms from './terms.pdf';
import privacy from './privacy.pdf';

function Footer(props) {
  const history = useHistory();
  return (
    <footer
      style={{
        width: '100%',
        background: ' #ecf0f4',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: `${props.position}`,
        left: 0,
        right: 0,
        bottom: 0
      }}>
      <Box display='flex' justifyContent='space-between'>
        <Typography style={{ padding: 20 }}>
          <a
            href={terms}
            style={{
              color: '#007bff',
              cursor: 'pointer',
              textDecoration: 'none'
            }}
            target='_blank'>
            Terms & Conditions
          </a>
        </Typography>
        <Typography style={{ padding: 20 }}>
          <a
            href={privacy}
            style={{
              color: '#007bff',
              cursor: 'pointer',
              textDecoration: 'none'
            }}
            target='_blank'>
            Privacy Policy
          </a>
        </Typography>
        <Typography style={{ padding: 20 }}>
          <a
            href={nda}
            style={{
              color: '#007bff',
              cursor: 'pointer',
              textDecoration: 'none'
            }}
            target='_blank'>
            NDA
          </a>
        </Typography>

        <Typography style={{ padding: 20, textAlign: 'right' }}>
          &copy;Copy Rights. All Rights Reserved.
        </Typography>
      </Box>
    </footer>
  );
}

export default Footer;
