import { hot } from 'react-hot-loader/root';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { toast } from 'react-toastify';
import PrivateRoute from './components/PrivateRoute';
import Welocome from 'views/Home';
import UserTimelinePage from 'views/UserTimelinePage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import MyCounter from './MyCounter'
import { SnackbarProvider } from 'notistack';

const loading = () => <LinearProgress />;

const Page404 = React.lazy(() => import('./pages/Page404'));
const Page500 = React.lazy(() => import('./pages/Page500'));

const DefaultLayout = React.lazy(() => import('./layouts/DefaultLayout'));
const PatientLayout = React.lazy(() => import('./layouts/PatientLayout'));

const theme = createMuiTheme({
  palette: {
    primary: { main: '#3C3B53' },
    secondary: { main: '#3C3B53' }
  }
});

toast.configure();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}>
        <Router>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route path='/404' render={props => <Page404 {...props} />} />
              <Router path='/500' render={props => <Page500 {...props} />} />
              <Route
                path='/welcome'
                render={props => <Welocome {...props} />}
              />
              <PrivateRoute
                path='/patient/:patient_id'
                render={props => <PatientLayout {...props} />}
              />
              <PrivateRoute path={`/patient-timeline/:id`}
                render={props => <UserTimelinePage {...props} />}
              >
              </PrivateRoute>
             <PrivateRoute
                path='/'
                render={props => <DefaultLayout {...props} />}
              />
            </Switch>
          </React.Suspense>
        </Router>
        <ToastContainer />
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default hot(App);
